import * as Routes from 'Routes';
import FetchHelpers from 'utils/FetchHelpers';
import { isDevelopment } from 'utils/Environment';
import { decamelize } from 'utils/keysConverter';

export default {
  makeRequest(params) {
    if (isDevelopment()) {
      return Promise.resolve();
    }

    const url = Routes.api_v2_pardot_path();
    const pardotParams = decamelize({ pardot: params });
    return FetchHelpers.post(url, pardotParams);
  },

  signUp(requestBody) {
    const pardotParams = {
      requestType: 'sign_up',
      requestBody,
    };
    return this.makeRequest(pardotParams);
  },

  scheduleCall(requestBody) {
    const pardotParams = {
      requestType: 'schedule_call',
      requestBody,
    };
    return this.makeRequest(pardotParams);
  },

  installChromeExtension(requestBody) {
    const pardotParams = {
      requestType: 'install_chrome_extension',
      requestBody,
    };
    return this.makeRequest(pardotParams);
  },

  domainsFormSubmit(requestBody) {
    const pardotParams = {
      requestType: 'domains_form_submit',
      requestBody,
    };
    return this.makeRequest(pardotParams);
  },
};
