export function isDevelopment() {
  return gon.env === 'development';
}

export function isQa() {
  return gon.env === 'qa';
}

export const env = {
  LAUNCH_ASSESSMENT_URL: gon.launchAssessmentUrl,
};
